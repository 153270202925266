import React from "react"
import ImpresstextMDX from "../sections/impresstext"
import { Grid, Box } from "theme-ui";

const Impress = () => (
    <Grid
        pt={100}
        pb={100}
        pl={4}
        pr={4}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="impress"
    >
        <Box>
            <ImpresstextMDX/>
        </Box>
    </Grid>
)

export default Impress;