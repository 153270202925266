import React from "react"
import Layout from "../components/layout"
import Bannerpage from "../components/bannerpage"
import Impress from "../components/impress"
import Footer from "../components/footer";
import { Divider } from "theme-ui";
import SEO from "../components/seo";

const MinimalImpress = () => (
    <>
         <Bannerpage/>
        <Layout>
            <SEO title="Impressum" />
            <Impress/>
            <Divider />
            <Footer/>
        </Layout>
    </>
)

export default MinimalImpress;