import React from "react"
import { Container, Box, NavLink } from 'theme-ui'
import BannerMDX from "../sections/banner"

const Bannerpage = () => (
    <Container
        pt={100}
        pb={100}
        bg='black'
        sx={{
            textAlign: 'center'
        }}
    >
        <Box sx={{
            color: "white"
        }}>
            <BannerMDX/>
        </Box>
        <Box as='nav'
            sx={{
                color: 'grey',
                fontSize: 'large'
            }}
        >
            <NavLink href='/' p={2}>
                Home
            </NavLink>
        </Box>
    </Container>
)

export default Bannerpage;